@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

:root {
  --primary: #1188aa;
  --secondary: #dbdbdb;
  --dark-blue: #00101f;
  --layout-color: #000;
  --text-gray: #cdcdce;
  --base-border-radius: 8px;
}

body[data-theme='light'] {
  --dark-blue: #001529;
  --layout-color: #fff;
  --text-gray: #323231;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 1140px;
}

.view {
  padding-top: 24px;
  padding-bottom: 24px;
}
